<template>
    <tr :class="{ 'bg-light': isReorderQuestion }">
        <td>
            {{ question.type }}
        </td>
        <td v-html="question.title" />
        <td v-html="question.subtitle" />
        <td v-if="['single', 'navigation'].includes(question.type)">
            Chosen option: {{ question.data.content }}
        </td>
        <td v-else-if="question.type === 'multiple'">
            <div class="table-responsive">
                <table
                    id="order-multiple-questionnaires-datatable"
                    class="table table-bordered"
                >
                    <tr>
                        <th>Option</th>
                        <th>Whole option</th>
                        <th>Left</th>
                        <th>Right</th>
                    </tr>
                    <tr v-for="(option, index) in question.data" :key="index">
                        <td>{{ option.content }}</td>
                        <td v-if="option.active === undefined">
                            -
                        </td>
                        <td v-else-if="option.active">
                            <span class="badge bg-soft-success text-success">
                                Active
                            </span>
                        </td>
                        <td v-else>
                            <span class="badge bg-soft-danger text-danger">
                                Inactive
                            </span>
                        </td>
                        <td v-if="option.left === undefined">
                            -
                        </td>
                        <td v-else-if="option.left">
                            <span class="badge bg-soft-success text-success">
                                Active
                            </span>
                        </td>
                        <td v-else>
                            <span class="badge bg-soft-danger text-danger">
                                Inactive
                            </span>
                        </td>
                        <td v-if="option.right === undefined">
                            -
                        </td>
                        <td v-else-if="option.right">
                            <span class="badge bg-soft-success text-success">
                                Active
                            </span>
                        </td>
                        <td v-else>
                            <span class="badge bg-soft-danger text-danger">
                                Inactive
                            </span>
                        </td>
                    </tr>
                </table>
            </div>
        </td>
        <td v-else>
            {{ question.data }}
        </td>
    </tr>
</template>

<script>
export default {
    props: {
        question: {
            type: Object,
            required: true,
            default: () => ({})
        }
    },

    computed: {
        isReorderQuestion() {
            const reorderQuestions = ['reorder_repeat', 'reorder_repeat_no'];

            return reorderQuestions.includes(this.question.questionIdentifier);
        }
    }
};
</script>
