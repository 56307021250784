import { render, staticRenderFns } from "./FactoryDataDetails.vue?vue&type=template&id=6c540e72&scoped=true&"
import script from "./FactoryDataDetails.vue?vue&type=script&lang=js&"
export * from "./FactoryDataDetails.vue?vue&type=script&lang=js&"
import style0 from "./FactoryDataDetails.vue?vue&type=style&index=0&id=6c540e72&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c540e72",
  null
  
)

export default component.exports