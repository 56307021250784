<template>
    <div class="card-box">
        <h5 class="text-uppercase p-2 mt-0 mb-2">
            Customer Images
        </h5>
        <div class="row">
            <div
                v-for="(image, index) in images"
                :key="index"
                class="col-xl-3 col-md-6 col-12 mb-3 image-tile"
            >
                <a :href="getImageUrl(image)" target="_blank">
                    <div class="d-flex mb-4 h-100 preview-image-container">
                        <div class="media-item-overlay">
                            <div class="media-preview-item-remove">
                                <button
                                    type="button"
                                    class="btn btn-danger rounded"
                                    @click.prevent="onDeleteImage(image.id)"
                                >
                                    <i class="far fa-trash-alt" />
                                </button>
                            </div>
                            <div class="media-item-title">
                                {{ image.name }}
                            </div>
                        </div>
                        <div class="media-container">
                            <img
                                class="img-fluid img-thumbnail"
                                :src="getImageUrl(image)"
                            />
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-xl-3 col-md-6 col-12 mb-3 image-tile">
                <label class="file_wrap h-100 w-100 preview-image-container">
                    <input
                        ref="fileInput"
                        type="file"
                        accept="image/png, image/jpeg, image/heic"
                        :disabled="uploading"
                        @change="onImageUpload"
                    />
                    <template v-if="uploading">
                        <b-spinner
                            variant="secondary"
                            role="status"
                            type="grow"
                        />
                        <span class="file_wrap_inner">
                            <span>
                                Uploading...
                            </span>
                            <span class="text-danger">
                                {{ error }}
                            </span>
                        </span>
                    </template>
                    <template v-else>
                        <i class="fe-upload-cloud me-1" />
                        <span class="file_wrap_inner">
                            <span>
                                Upload photo
                            </span>
                            <span class="text-danger">
                                {{ error }}
                            </span>
                        </span>
                    </template>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import config from '@config';
import { mapActions } from 'vuex';

const MAX_FILE_SIZE = 31457280;

export default {
    props: {
        images: {
            type: Array,
            required: true
        },
        orderProductColorId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            uploading: false,
            error: null
        };
    },

    methods: {
        ...mapActions({
            uploadImage: 'orderProductColors/uploadImage',
            deleteImage: 'orderProductColors/deleteImage'
        }),

        getImageUrl(image) {
            return `${config.mediaUrl}/images/${image.date}/${image.filename ||
                image.id}.${image.extension}`;
        },

        async onImageUpload() {
            this.error = null;

            const image = this.$refs.fileInput.files[0];

            if (!image) {
                return null;
            }

            if (image.size > MAX_FILE_SIZE) {
                this.error =
                    'This image is too big. Image can not be bigger than 30MB.';

                return;
            }

            try {
                this.uploading = true;

                const images = await this.uploadImage({
                    id: this.orderProductColorId,
                    image
                });

                this.$emit('image-uploaded', images);

                this.$toaster('Image has been uploaded!');
            } catch (err) {
                console.error(err);

                this.error = 'Something went wrong. Please try again.';
            } finally {
                this.uploading = false;

                this.$refs.fileInput.value = null;
            }
        },

        async onDeleteImage(imageId) {
            const confirmed = await this.$confirmationModal(
                'Do you want to delete this item?',
                {
                    title: 'Are you sure?'
                }
            );

            if (!confirmed) {
                return;
            }

            try {
                await this.deleteImage({
                    id: this.orderProductColorId,
                    imageId
                });

                this.$emit('image-deleted', imageId);

                this.$toaster('Image has been removed!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.img-fluid {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 100% !important;
}

.file_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    [type='file'] {
        width: 0;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
    }

    i {
        font-size: 60px;
    }

    .spinner-grow {
        width: 40px;
        height: 40px;
        margin: 10px;
    }
}

.file_wrap_inner {
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
    flex-direction: column;
}
</style>
