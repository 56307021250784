<template>
    <div class="card-box">
        <h5 class="p-2 m-0 mb-2 text-uppercase">
            Post Purchase Questionnaire
        </h5>
        <div class="row">
            <div class="form-group col-12 table-responsive">
                <table class="table table-bordered text-center">
                    <thead class="thead-light">
                        <tr>
                            <th>Question type</th>
                            <th>Question title</th>
                            <th>Question subtitle</th>
                            <th>Answer</th>
                        </tr>
                    </thead>
                    <tbody>
                        <question-answer
                            v-for="(question, index) in accountPostPurchaseQuestionnaire"
                            :key="
                                `questionPostPurchase-${index}`
                            "
                            :question="question"
                        />
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import QuestionAnswer from '@components/orders/details/QuestionAnswer';

export default {
    components: {
        QuestionAnswer
    },

    props: {
        postPurchaseQuestionnaire: {
            type: Object,
            required: true
        },
        account: {
            type: Object,
            required: true
        }
    },

    computed: {
        accountPostPurchaseQuestionnaire() {
            const { answers } = this.postPurchaseQuestionnaire.answers.find(
                answer => answer.account.index === this.account.index
            );

            return answers;
        }
    }
}
</script>
