<template>
    <div class="card-box">
        <h5 class="p-2 m-0 mb-2 text-uppercase">
            Zendesk Tickets
        </h5>

        <div role="tablist">
            <b-card
                v-if="!tickets.length"
                no-body
                class="mb-1"
            >
                <b-card-header class="bg-light" style="height: 50px;">
                    <h5 class="m-0 text-center">
                        <b-spinner
                            v-if="isLoading"
                            class="m-0"
                            style="width: 16px; height: 16px"
                            variant="black"
                            role="status"
                            type="grow"
                        />
                        <span v-if="isLoading" class="ml-1">
                            Loading...
                        </span>
                        <span v-else>
                            No Zendesk tickets
                        </span>
                    </h5>
                </b-card-header>
            </b-card>

            <b-card
                v-for="ticket in tickets"
                :key="ticket.id"
                no-body
                class="mb-1"
            >
                <b-card-header
                    v-b-toggle="`accordion-${ticket.id}`"
                    header-tag="header"
                    role="tab"
                    class="cursor-pointer"
                >
                    <h5 class="m-0">
                        <a class="text-dark">
                            <span>#{{ ticket.id }}</span>
                            <span class="ml-2">{{ ticket.subject }}</span>
                            <span
                                class="text-uppercase float-right"
                                :class="getStatusBadgeClass(ticket.status)"
                            >
                                {{ ticket.status }}
                            </span>
                            <span class="float-right mr-2">
                                {{ ticket.created_at | formatDateChat }}
                            </span>
                        </a>
                    </h5>
                </b-card-header>

                <b-collapse
                    :id="`accordion-${ticket.id}`"
                    :visible="false"
                    role="tabpanel"
                >
                    <b-card-body>
                        <div class="chat-conversation">
                            <ul class="conversation-list">
                                <li
                                    v-for="comment in ticket.comments"
                                    :key="comment.id"
                                    :class="{
                                        odd: comment.user.role !== 'end-user',
                                        'non-public': !comment.public
                                    }"
                                    class="clearfix"
                                >
                                    <div class="chat-avatar">
                                        <img
                                            v-if="comment.user.photo"
                                            :src="comment.user.photo.content_url"
                                            class="rounded avatar-img"
                                            alt="avatar"
                                        />
                                        <div v-else class="avatar-md">
                                            <span
                                                :class="getAvatarClass(comment.user.role)"
                                            >
                                                {{ getAvatarText(comment.user.name) }}
                                            </span>
                                        </div>
                                        <i class="font-weight-bold mt-1">
                                            {{ comment.created_at | formatDateChat }}
                                        </i>
                                    </div>
                                    <div class="conversation-text">
                                        <div class="ctext-wrap">
                                            <i>{{ comment.user.name }}</i>
                                            <div v-html="comment.html_body" />
                                        </div>

                                        <div v-if="comment.attachments.length" class="mt-2">
                                            <i class="fa fa-paperclip" />
                                            Attachments ({{ comment.attachments.length }})
                                        </div>

                                        <div class="row attachments-row">
                                            <div
                                                v-for="(attachment, index) in comment.attachments"
                                                :key="index"
                                                class="col-xl-4 col-lg-6"
                                            >
                                                <div class="card m-1 p-2 shadow-none border">
                                                    <div class="row align-items-center">
                                                        <div class="col-auto pr-0">
                                                            <div class="avatar-sm">
                                                                <span class="avatar-title bg-light text-secondary rounded">
                                                                    <i
                                                                        :class="getAttachmentIcon(attachment.content_type)"
                                                                        class="font-20"
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <a
                                                                :href="attachment.content_url"
                                                                target="_blank"
                                                                class="text-muted font-weight-bold"
                                                            >
                                                                {{ attachment.file_name }}
                                                            </a>
                                                            <p class="mb-0 font-13">
                                                                {{ getAttachmentSize(attachment.size) }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        orderId: {
            type: String,
            required: false,
            default: null
        }
    },

    data() {
        return {
            tickets: [],
            isLoading: true
        };
    },

    async mounted() {
        const id = this.orderId || this.$route.params.id;

        await this.fetchTicket(id);

        this.isLoading = false;
    },

    methods: {
        ...mapActions({
            getTickets: 'orders/getZendeskTickets'
        }),

        async fetchTicket(id) {
            try {
                this.tickets = await this.getTickets(id);
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        getStatusBadgeClass(status) {
            let color = 'success';

            if (status === 'open') {
                color = 'danger';
            }

            if (status === 'pending') {
                color = 'warning';
            }

            if (status === 'closed') {
                color = 'teal';
            }

            return `badge badge-${color}`;
        },

        getAvatarText(fullName) {
            fullName = fullName.replace(/[()]/g, "");

            let names = fullName.split(' ');

            if (names.length === 1) {
                return names[0].substring(0, 2);
            } else {
                return `${names[0].charAt(0)}${names[1].charAt(0)}`;
            }
        },

        getAvatarClass(role) {
            let color = 'info';

            if (role !== 'end-user') {
                color = 'warning';
            }

            return `avatar-title bg-soft-${color} text-${color} font-24 rounded`;
        },

        getAttachmentSize(size) {
            const kbSize = size / 1000;

            if (kbSize < 1024) {
                return `${kbSize.toFixed(1)} KB`;
            } else {
                return `${(kbSize / 1024).toFixed(1)} MB`;
            }
        },

        getAttachmentIcon(contentType) {
            if (contentType.startsWith('image/')) {
                return 'mdi mdi-file-image';
            }

            if (contentType.startsWith('video/')) {
                return 'mdi mdi-file-video';
            }

            if (contentType === 'application/pdf') {
                return 'mdi mdi-file-pdf';
            }

            return 'mdi mdi-file';
        }
    }
};
</script>

<style lang="scss" scoped>
.conversation-list {
    height: unset;

    .chat-avatar {
        width: 62px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .odd .ctext-wrap {
        background-color: #f7f7f7;

        &:after {
            border-left-color: #f7f7f7;
            border-top-color: #f7f7f7;
        }
    }

    .non-public .ctext-wrap {
        background-color: #fef5e4;

        &:after {
            border-left-color: #fef5e4;
            border-top-color: #fef5e4;
        }
    }

    .odd .attachments-row {
        justify-content: end;
        margin: 0 -18px;
        text-align: left;
    }

    .conversation-text {
        margin-left: 16px;
        margin-right: 16px;
    }

    .avatar-img {
        height: 56px;
        width: 56px;
        object-fit: cover;
    }
}
</style>
