<template>
    <div class="d-flex flex-wrap">
        <div class="from-group col-12 table-responsive">
            <table
                id="order-notes-datatable"
                class="table table-centered table-bordered text-center"
            >
                <thead class="thead-light">
                    <tr>
                        <th>Number</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Changed by</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(status, index) in orderedHistories" :key="status.id">
                        <td>{{ histories.length - index }}</td>
                        <td>{{ status.created_at | formatDate }}</td>
                        <td>
                            {{ status.status || status.logistics_status | removeUnderscore }}
                        </td>
                        <td v-if="status.user">
                            {{ `${status.user.first_name} ${status.user.last_name}` }}
                        </td>
                        <td v-else>
                            {{ status.changed_by }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        histories: {
            type: Array,
            required: false,
            default: () => []
        }
    },

    computed: {
        orderedHistories() {
            const histories = this.histories;

            return histories.sort(
                (a, b) => new Date(b.created_at) - new Date(a.created_at)
            );
        }
    }
};
</script>
