<template>
    <layout>
        <page-header :title="title" :items="items" />

        <div class="row">
            <div class="col-sm-12 mb-2 text-right">
                <button
                    v-if="orderProductColor.quantity > 1"
                    class="btn btn-primary mr-2"
                    @click="splitItem"
                >
                    <i class="mdi mdi-call-split mr-1" />
                    Split Item
                </button>

                <button
                    class="btn btn-blue mr-2"
                    @click="$bvModal.show('changeStyleModal')"
                >
                    <i class="fas fa-brush mr-1" />
                    Change Style
                </button>

                <button
                    class="btn btn-success"
                    @click="saveChanges"
                >
                    <i class="fas fa-save mr-1" />
                    Save Changes
                </button>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 col-xl-6 d-flex">
                <div class="card-box flex-fill">
                    <h5 class="text-uppercase py-2 mt-0">
                        <i class="fe-file-text mr-1" />
                        General
                    </h5>
                    <h4 class="font-13 text-muted text-uppercase">
                        Assignee :
                    </h4>
                    <div class="form-group d-flex mb-input">
                        <b-form-select
                            v-model="orderProductColor.user_id"
                            :options="usersOptions"
                            class="form-control form-control-sm bg-light border-0"
                        />
                    </div>
                    <h4 class="font-13 text-muted text-uppercase">
                        Priority :
                    </h4>
                    <div class="form-group d-flex mb-input">
                        <b-form-select
                            v-model="orderProductColor.priority"
                            :options="priorities"
                            class="form-control form-control-sm bg-light border-0"
                        />
                    </div>
                    <h4 class="font-13 text-muted text-uppercase">
                        FedEx Tracking Numbers :
                    </h4>
                    <div class="form-group d-flex mb-input">
                        <input
                            v-model="fedexTrackingNumbers"
                            class="form-control form-control-sm bg-light border-0"
                            type="text"
                        />
                    </div>
                    <h4 class="font-13 text-muted text-uppercase">
                        3D Scan :
                    </h4>
                    <div class="form-group d-flex mb-input">
                        <input
                            v-model="orderProductColor.scan_link"
                            class="form-control form-control-sm bg-light border-0"
                            type="text"
                        />
                        <a
                            :href="orderProductColor.scan_link"
                            target="_blank"
                            class="d-flex"
                        >
                            <button
                                class="btn btn-xs btn-info ml-2"
                                :disabled="!orderProductColor.scan_link"
                            >
                                Open
                            </button>
                        </a>
                    </div>
                    <h4 class="font-13 text-muted text-uppercase">
                        ORT File :
                    </h4>
                    <div class="form-group d-flex mb-input">
                        <input
                            v-model="orderProductColor.ort_file_link"
                            class="form-control form-control-sm bg-light border-0"
                            type="text"
                        />
                        <a
                            :href="orderProductColor.ort_file_link"
                            target="_blank"
                            class="d-flex"
                        >
                            <button
                                class="btn btn-xs btn-info ml-2"
                                :disabled="!orderProductColor.ort_file_link"
                            >
                                Open
                            </button>
                        </a>
                    </div>
                    <h4 class="font-13 text-muted text-uppercase">
                        ORT File 2 :
                    </h4>
                    <div class="form-group d-flex mb-input">
                        <input
                            v-model="orderProductColor.ort_file_link_2"
                            class="form-control form-control-sm bg-light border-0"
                            type="text"
                        />
                        <a
                            :href="orderProductColor.ort_file_link_2"
                            target="_blank"
                            class="d-flex"
                        >
                            <button
                                class="btn btn-xs btn-info ml-2"
                                :disabled="!orderProductColor.ort_file_link_2"
                            >
                                Open
                            </button>
                        </a>
                    </div>
                    <h4 class="font-13 text-muted text-uppercase">
                        Outgoing Tracking :
                    </h4>
                    <div class="form-group d-flex mb-input">
                        <input
                            v-model="orderProductColor.outgoing_tracking"
                            class="form-control form-control-sm bg-light border-0"
                            type="text"
                        />
                    </div>
                    <h4 class="font-13 text-muted text-uppercase">
                        Incoming Tracking :
                    </h4>
                    <div class="form-group d-flex mb-input">
                        <input
                            v-model="orderProductColor.incoming_tracking"
                            class="form-control form-control-sm bg-light border-0"
                            type="text"
                        />
                    </div>
                </div>
            </div>

            <div class="col-md-12 col-xl-6 d-flex">
                <div class="card-box flex-fill">
                    <h5 class="text-uppercase py-2 mt-0">
                        <i class="fe-edit mr-1" />
                        Notes
                    </h5>
                    <textarea
                        id="contentTitle"
                        v-model="orderProductColor.note"
                        class="form-control bg-light border-0"
                        rows="25"
                    />
                </div>
            </div>

            <div class="col-md-6 col-xl-3 d-flex">
                <div class="card-box flex-fill">
                    <h5 class="text-uppercase py-2 mt-0">
                        <i class="fe-user mr-1" />
                        User Data
                    </h5>
                    <div class>
                        <h4 class="font-13 text-muted text-uppercase">
                            Order Number :
                        </h4>
                        <p class="mb-3">
                            {{ orderProductColor.order.order_number }}
                        </p>
                        <h4 class="font-13 text-muted text-uppercase">
                            Order Date :
                        </h4>
                        <p class="mb-3">
                            {{ orderProductColor.order.created_at | formatDate }}
                        </p>
                        <h4 class="font-13 text-muted text-uppercase">
                            Customer Email :
                        </h4>
                        <p class="mb-3">
                            {{ orderProductColor.order.customer.email }}
                        </p>
                        <h4 class="font-13 text-muted text-uppercase">
                            Customer Name :
                        </h4>
                        <p class="mb-3">
                            {{
                                `${orderProductColor.order.customer.first_name}
                                ${orderProductColor.order.customer.last_name}`
                            }}
                        </p>
                        <h4 class="font-13 text-muted text-uppercase">
                            Account Name :
                        </h4>
                        <div class="form-group d-flex mb-input">
                            <input
                                v-model="orderProductColor.account.name"
                                class="form-control form-control-sm bg-light border-0"
                                type="text"
                            />
                        </div>
                        <h4 class="font-13 text-muted text-uppercase">
                            Dropbox Link :
                        </h4>
                        <div class="form-group d-flex mb-input">
                            <input
                                v-model="orderProductColor.order.customer.dropbox_sharing_link"
                                class="form-control form-control-sm bg-light border-0"
                                type="text"
                                disabled
                            />
                            <a
                                :href="orderProductColor.order.customer.dropbox_sharing_link"
                                target="_blank"
                                class="d-flex"
                            >
                                <button
                                    class="btn btn-xs btn-info ml-2"
                                    :disabled="!orderProductColor.order.customer.dropbox_sharing_link"
                                >
                                    Open
                                </button>
                            </a>
                        </div>
                        <h4 class="font-13 text-muted text-uppercase">
                            Customer Orders Number :
                        </h4>
                        <p class="mb-3">
                            {{ orderProductColor.order.customer.orders_count }}
                            <a
                                :href="`${appUrl}/customers/${orderProductColor.order.customer_id}/factory-data`"
                                target="_blank"
                                class="action-icon"
                                @click.stop
                            >
                                <i class="fe-external-link" />
                            </a>
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-xl-3 d-flex">
                <div class="card-box flex-fill">
                    <h5 class="text-uppercase py-2 mt-0">
                        <i class="fe-package mr-1" />
                        Product Details
                    </h5>
                    <div class>
                        <h4 class="font-13 text-muted text-uppercase">
                            Name :
                        </h4>
                        <p class="mb-3">
                            {{ orderProductColor.productColor.product.name }}
                        </p>
                        <h4 class="font-13 text-muted text-uppercase">
                            SKU :
                        </h4>
                        <p class="mb-3">
                            {{ orderProductColor.productColor.product.sku || orderProductColor.assigned_sku }}
                        </p>
                        <h4 class="font-13 text-muted text-uppercase">
                            Reorder Number :
                        </h4>
                        <p class="mb-3">
                            {{
                                orderProductColor.magento_order_number
                                    ? `${orderProductColor.magento_order_number} (Magento)`
                                    : orderProductColor.reorder_number || '-'
                            }}
                        </p>
                        <h4 class="font-13 text-muted text-uppercase">
                            Exact Same :
                        </h4>
                        <p class="mb-3">
                            {{
                                orderProductColor.reorder_number
                                    ? 'Yes'
                                    : '-'
                            }}
                        </p>
                        <h4 class="font-13 text-muted text-uppercase">
                            From One Time Offer :
                        </h4>
                        <p class="mb-3">
                            {{ orderProductColor.from_one_time_offer ? 'Yes' : 'No' }}
                        </p>
                        <h4 class="font-13 text-muted text-uppercase">
                            From Bundle :
                        </h4>
                        <p class="mb-3">
                            {{ orderProductColor.from_bundle ? 'Yes' : 'No' }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-xl-3 d-flex">
                <div class="card-box flex-fill">
                    <h5 class="text-uppercase py-2 mt-0">
                        <i class="fe-check-square mr-1" />
                        User Input
                    </h5>
                    <div class>
                        <h4 class="font-13 text-muted text-uppercase">
                            Gender :
                        </h4>
                        <div class="form-group d-flex mb-input">
                            <b-form-select
                                v-model="orderProductColor.account.genderIdentifier"
                                :options="genders"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                        <h4 class="font-13 text-muted text-uppercase">
                            Removable Inserts :
                        </h4>
                        <div class="form-group d-flex mb-input">
                            <b-form-select
                                v-model="orderProductColor.removable_inserts"
                                :options="removableInsertsOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                        <h4 class="font-13 text-muted text-uppercase">
                            Color :
                        </h4>
                        <div class="form-group d-flex mb-input">
                            <span
                                :style="{
                                    'background-color': selectedColor.hex,
                                }"
                                class="colour-block mr-1"
                                :class="{ 'is-white': selectedColor.hex === '#FFFFFF' }"
                            />
                            <b-form-select
                                v-model="selectedColor"
                                :options="colorsOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                        <h4 class="font-13 text-muted text-uppercase">
                            Quantity :
                        </h4>
                        <div class="form-group d-flex mb-input">
                            <input
                                v-model.number="orderProductColor.quantity"
                                class="form-control form-control-sm bg-light"
                                :class="{
                                    'border-0': !$v.orderProductColor.quantity.$error,
                                    'border-danger': $v.orderProductColor.quantity.$error
                                }"
                                type="number"
                                min="1"
                            />
                        </div>
                        <h4 class="font-13 text-muted text-uppercase">
                            Shoe Size :
                        </h4>
                        <div class="form-group d-flex mb-input">
                            <b-form-select
                                v-model="orderProductColor.size"
                                :options="sizeOptions"
                                class="form-control form-control-sm bg-light border-0"
                            />
                        </div>
                        <h4 class="font-13 text-muted text-uppercase">
                            Shoe Size EU {{ genderText }} :
                        </h4>
                        <p class="mb-3">
                            {{ euSize }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-xl-3 d-flex">
                <div class="card-box flex-fill">
                    <h5 class="text-uppercase py-2 mt-0">
                        <i class="fe-repeat mr-1" />
                        Remake
                    </h5>
                    <div class>
                        <h4 class="font-13 text-muted text-uppercase">
                            Remake guarantee days :
                        </h4>
                        <p class="mb-3">
                            {{ remake.daysLeft }} {{ remake.isShipped ? '' : '(Not shipped)' }}
                        </p>
                        <h4 class="font-13 text-muted text-uppercase">
                            Remake quantity :
                        </h4>
                        <p class="mb-3">
                            {{ remake.quantity }}
                        </p>
                        <h4 class="font-13 text-muted text-uppercase">
                            Remake guarantee allow :
                        </h4>
                        <p class="mb-3">
                            {{ remake.allow }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-box">
            <h5 class="p-2 m-0 mb-2 text-uppercase">
                Logistics Status
            </h5>
            <div class="d-flex flex-wrap">
                <div class="form-group col-lg-6">
                    <b-form-select
                        v-model="orderProductColor.status"
                        :options="statuses"
                    />
                </div>
                <div class="form-group col-lg-6 d-flex align-items-end">
                    <button
                        type="button"
                        class="btn btn-danger waves-effect waves-light ml-1"
                        @click="changeStatus(false)"
                    >
                        Change
                    </button>
                    <button
                        type="button"
                        class="btn btn-danger waves-effect waves-light ml-2"
                        @click="changeStatus(true)"
                    >
                        Change For All Order Items
                    </button>
                </div>
            </div>
            <order-history-table
                :histories="orderProductColor.history"
            />
        </div>

        <div
            v-if="
                orderProductColor.questionnaire &&
                    orderProductColor.questionnaire.length
            "
            class="card-box"
        >
            <h5 class="p-2 m-0 mb-2 text-uppercase">
                Questionnaire
            </h5>
            <div class="row">
                <div class="form-group col-12 table-responsive">
                    <table
                        v-if="orderProductColor.questionnaire && orderProductColor.questionnaire.length"
                        id="order-questionnaires-datatable"
                        class="table table-bordered text-center"
                    >
                        <thead class="thead-light">
                            <tr>
                                <th>Question type</th>
                                <th>Question title</th>
                                <th>Question subtitle</th>
                                <th>Answer</th>
                            </tr>
                        </thead>
                        <tbody>
                            <question-answer
                                v-for="(question, qIndex) in getQuestions(
                                    orderProductColor.questionnaire,
                                    orderProductColor.account,
                                    orderProductColor
                                )"
                                :key="`question-${qIndex}`"
                                :question="question"
                            />
                        </tbody>
                    </table>

                    <table
                        v-else
                        class="table table-bordered text-center table-striped"
                    >
                        <tr>
                            <th>
                                Your questionnaire information is stored
                                in secure place! Order number:
                                {{ orderProductColor.magento_order_number }}
                            </th>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <post-purchase-questionnaire
            v-if="orderProductColor.order.post_purchase_completed_at"
            :post-purchase-questionnaire="
                orderProductColor.order.postPurchaseQuestionnaire
            "
            :account="orderProductColor.account"
        />

        <zendesk-tickets
            v-if="orderProductColor.order.id"
            :order-id="orderProductColor.order.id"
        />

        <customer-images
            v-if="orderProductColor.id"
            :images="orderProductColor.images"
            :order-product-color-id="orderProductColor.id"
            @image-uploaded="onImageUploaded"
            @image-deleted="onImageDeleted"
        />

        <change-style-modal
            v-if="orderProductColor.id"
            :id="'changeStyleModal'"
            :order-product-color="orderProductColor"
            :products="products"
        />
    </layout>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import { integer, minValue } from 'vuelidate/lib/validators';
import config from '@/config';
import appConfig from '@src/app.config';
import OrderHistoryTable from '@components/orders/OrderHistoryTable';
import QuestionAnswer from '@components/orders/details/QuestionAnswer';
import PostPurchaseQuestionnaire from '@components/factory-data/PostPurchaseQuestionnaire';
import ChangeStyleModal from '@components/factory-data/ChangeStyleModal';
import CustomerImages from '@components/factory-data/CustomerImages';
import ZendeskTickets from '@components/factory-data/ZendeskTickets';

export default {
    page: {
        title: 'Factory Data Details',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    components: {
        OrderHistoryTable,
        QuestionAnswer,
        PostPurchaseQuestionnaire,
        ChangeStyleModal,
        CustomerImages,
        ZendeskTickets
    },

    data() {
        return {
            title: 'Factory Data Details',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Factory Data',
                    to: '/factory-data'
                },
                {
                    text: 'Details',
                    active: true
                }
            ],
            appUrl: config.url,
            orderProductColor: {
                account: {},
                order: {
                    customer: {}
                },
                productColor: {
                    product: {}
                },
                history: []
            },
            selectedColor: {},
            fedexTrackingNumbers: '',
            initialStatus: null,
            products: [],
            users: [],
            sizes: [],
            genders: [
                { text: 'Man', value: 'gender_men' },
                { text: 'Woman', value: 'gender_women' }
            ],
            removableInsertsOptions: [
                { text: 'Yes', value: 'yes' },
                { text: 'No', value: 'no' }
            ],
            priorities: [
                { text: 'Normal', value: 'NORMAL' },
                { text: 'High', value: 'HIGH' },
                { text: 'Very High', value: 'VERY_HIGH' },
                { text: 'Expedited Done', value: 'EXPEDITED_DONE' }
            ],
            statuses: [
                { text: '- Status -', disabled: true },
                'ORDER RECEIVED',
                'SENT IMPRESSION KIT',
                'SCANNED & READY FOR DESIGN',
                'DESIGNED & READY FOR PRODUCTION',
                'PRODUCTION PROCESS WITH SHIPMENT',
                'SHIP TO CUSTOMER',
                `DESIGN - WAITING ON CUSTOMER'S REPLY`,
                'ENTERING PRODUCTION LINE',
                'HOLD',
                'ADDRESS VERIFICATION',
                'RTS',
                'OLD ORDER',
                { text: '- Remake -', disabled: true },
                `REM - WAITING FOR CUSTOMER'S REPLY`,
                'REM - DESIGNED & READY FOR PRODUCTION',
                'REM - PRODUCTION',
                'REM - SHIP TO CUSTOMER',
                { text: '- Cancelation -', disabled: true },
                'REQUEST FOR CANCELATION PRE SHIP',
                'CANCELED PRE SHIP',
                'REQUEST FOR CANCELATION AFTER SHIP',
                'CANCELED',
                'ITEM HAS BEEN RETURNED'
            ]
        };
    },

    validations: {
        orderProductColor: {
            quantity: {
                integer,
                minValue: minValue(1)
            }
        }
    },

    computed: {
        usersOptions() {
            const designers = this.users.filter(
                user =>
                    user.acl &&
                    (user.acl.permissions.all || user.acl.permissions.designer)
            );

            const sortedDesigners = designers.sort((a, b) =>
                a.first_name.localeCompare(b.first_name)
            );

            const options = sortedDesigners.map(user => ({
                text: `${user.first_name} ${user.last_name}`,
                value: user.id
            }));

            options.unshift({
                text: '- Not Selected -',
                value: null
            });

            return options;
        },

        sizeOptions() {
            return this.sizes.map(size => size.size);
        },

        colorsOptions() {
            if (!this.orderProductColor.productColor.product.colors) {
                return [];
            }

            return this.orderProductColor.productColor.product.colors.map(
                color => ({
                    text: color.name,
                    value: {
                        id: color.id,
                        name: color.name,
                        hex: color.hex
                    }
                })
            );
        },

        genderText() {
            if (this.orderProductColor.account.genderIdentifier === 'gender_men') {
                return 'Men';
            }

            if (this.orderProductColor.account.genderIdentifier === 'gender_women') {
                return 'Women';
            }

            return null;
        },

        euSize() {
            const size = this.sizes.find(
                size => Number(size.size) === Number(this.orderProductColor.size)
            );

            if (!size) {
                return '-';
            }

            if (this.genderText === 'Men') {
                return size.eu_size_men;
            } else {
                return size.eu_size_women;
            }
        },

        remake() {
            let daysLeft = 180;

            const shippedStatus = this.orderProductColor.history.find(
                status => status.status === 'SHIP_TO_CUSTOMER'
            );

            if (shippedStatus) {
                daysLeft -= moment().diff(
                    moment(shippedStatus.created_at),
                    'days'
                );

                if (daysLeft < 0) {
                    daysLeft = 0;
                }
            }

            const remShippedStatuses = this.orderProductColor.history.filter(
                status => status.status === 'REM_-_SHIP_TO_CUSTOMER'
            );

            let quantity = 2 - remShippedStatuses.length;

            if (quantity < 0) {
                quantity = 0;
            }

            const allow = daysLeft === 0 || quantity === 0 ? 'No' : 'Yes';

            return {
                isShipped: !!shippedStatus,
                daysLeft,
                quantity,
                allow
            };
        }
    },

    async mounted() {
        this.orderProductColorId = this.$route.params.id;
        this.fetchOrderProductColor();
        this.fetchUsers();
        this.sizes = await this.getSizes();

        await this.fetchProducts();
    },

    methods: {
        ...mapActions({
            getOrderProductColor: 'orderProductColors/show',
            updateOrderProductColor: 'orderProductColors/update',
            changeOrderProductColorAssignee: 'orderProductColors/changeAssignee',
            changeOrderProductColorStatus: 'orderProductColors/changeStatus',
            splitOrderProductColor: 'orderProductColors/split',
            getProducts: 'products/index',
            getUsers: 'users/index',
            getSizes: 'productSizes/index'
        }),

        async fetchOrderProductColor() {
            try {
                this.orderProductColor = await this.getOrderProductColor(
                    this.orderProductColorId
                );

                this.initialStatus = this.orderProductColor.status;

                const { productColor } = this.orderProductColor;

                this.selectedColor = {
                    id: productColor.id,
                    name: productColor.name,
                    hex: productColor.hex
                };

                if (!this.orderProductColor.account.name) {
                    this.orderProductColor.account.name = `${
                        this.orderProductColor.order.customer.first_name
                    } ${this.orderProductColor.order.customer.last_name}`;
                }

                if (this.orderProductColor.fedex_tracking_numbers) {
                    this.fedexTrackingNumbers = this.orderProductColor.fedex_tracking_numbers.join(', ');
                }
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async fetchProducts() {
            try {
                const { rows } = await this.getProducts({ colors: true });

                this.products = rows;
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async fetchUsers() {
            const { rows } = await this.getUsers({
                perPage: 100
            });

            this.users = rows;
        },

        async saveChanges() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            if (this.orderProductColor.status !== this.initialStatus) {
                const newStatus = await this.changeStatus();

                if (!newStatus) {
                    return;
                }
            }

            try {
                await this.changeOrderProductColorAssignee({
                    id: this.orderProductColorId,
                    userId: this.orderProductColor.user_id
                });

                const trackingNumbersArray = this.fedexTrackingNumbers
                    ? this.fedexTrackingNumbers.replace(/ /g, '').split(',')
                    : [];

                await this.updateOrderProductColor({
                    id: this.orderProductColorId,
                    priority: this.orderProductColor.priority,
                    outgoing_tracking: this.orderProductColor.outgoing_tracking,
                    incoming_tracking: this.orderProductColor.incoming_tracking,
                    fedex_tracking_numbers: trackingNumbersArray,
                    scan_link: this.orderProductColor.scan_link,
                    ort_file_link: this.orderProductColor.ort_file_link,
                    ort_file_link_2: this.orderProductColor.ort_file_link_2,
                    account: this.orderProductColor.account,
                    removable_inserts: this.orderProductColor.removable_inserts,
                    quantity: this.orderProductColor.quantity,
                    product_color_id: this.selectedColor.id,
                    size: this.orderProductColor.size,
                    note: this.orderProductColor.note
                });

                this.$toaster('Changes have been saved!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async splitItem() {
            try {
                const confirmed = await this.$confirmationModal(
                    'Do you want to split this item?', {
                        title: 'Are you sure?'
                    }
                );

                if (!confirmed) {
                    return;
                }

                await this.splitOrderProductColor(this.orderProductColorId);

                this.orderProductColor.quantity -= 1;

                this.$toaster('Item has been split!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async changeStatus(allInOrder) {
            try {
                let updateScanDate = false;

                if (
                    this.orderProductColor.status ===
                        'DESIGNED & READY FOR PRODUCTION' &&
                    !this.orderProductColor.instruction
                ) {
                    this.$okModal(`You can't save this status without having an instruction file.`, {
                        title: 'Create instruction file'
                    });

                    return;
                }

                if (
                    this.orderProductColor.status ===
                        'SCANNED & READY FOR DESIGN' &&
                    this.orderProductColor.scan_date
                ) {
                    const confirmed = await this.$confirmationModal(
                        'This item already have a scan date. Would you like to change it?'
                    );

                    if (confirmed) {
                        updateScanDate = true;
                    }
                }

                const newStatus = await this.changeOrderProductColorStatus({
                    id: this.orderProductColorId,
                    status: this.orderProductColor.status,
                    allInOrder,
                    updateScanDate
                });

                this.initialStatus = newStatus.status;

                this.$toaster('Status has been updated!');

                this.orderProductColor.history = [
                    newStatus,
                    ...this.orderProductColor.history
                ];

                return newStatus;
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        getQuestions(productQuestionnaire, account, product) {
            if (!this.postPurchaseQuestionnaire) {
                return productQuestionnaire;
            }

            const currentAccountQuestionnaire = this.postPurchaseQuestionnaire.answers.find(
                item => item.account.index === account.info.index
            );
            const defaultPostPurchaseQuestions = currentAccountQuestionnaire.answers.filter(
                answer => answer.isDefault && answer.productId === product.id
            );

            return [...productQuestionnaire, ...defaultPostPurchaseQuestions];
        },

        onImageUploaded(images) {
            this.orderProductColor.images = images;
        },

        onImageDeleted(imageId) {
            this.orderProductColor.images = this.orderProductColor.images.filter(
                image => image.id !== imageId
            );
        }
    }
};
</script>

<style lang="scss" scoped>
.mb-input {
    margin-bottom: 14px;
}

.colour-block {
    height: 31px;
    width: 31px;
    flex-shrink: 0;
    border-radius: 0.2rem;
    background-color: #F1F5F7;

    &.is-white {
        border: 1px solid #ced4da;
    }
}
</style>

