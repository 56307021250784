<template>
    <b-modal
        :id="id"
        class="modal-demo"
        title-class="text-light"
        header-bg-variant="dark"
        header-text-variant="light"
        title-tag="h4"
        size="md"
        centered
        @show="openModalHandler"
        @hidden="hideModal"
    >
        <template #modal-title>
            Change Orthotics Style
        </template>

        <div class="custom-modal-text">
            <h4 class="font-13 text-muted text-uppercase">
                Product :
            </h4>
            <div class="form-group d-flex mb-input">
                <b-form-select
                    v-model="selectedProduct"
                    :options="productOptions"
                    class="form-control form-control-sm bg-light border-0"
                    @change="onProductChange"
                />
            </div>
            <h4 class="font-13 text-muted text-uppercase">
                Color :
            </h4>
            <div class="form-group d-flex mb-input">
                <span
                    :style="{
                        'background-color': selectedColor.hex
                    }"
                    class="colour-block mr-1"
                    :class="{ 'is-white': selectedColor.hex === '#FFFFFF' }"
                />
                <b-form-select
                    v-model="selectedColor"
                    :options="colorOptions"
                    class="form-control form-control-sm bg-light border-0"
                />
            </div>

            <h4 class="font-13 text-muted text-uppercase">
                Old Question (Will Be Removed) :
            </h4>
            <div class="form-group d-flex mb-input">
                <b-form-select
                    v-model="selectedOldQuestion"
                    :options="oldQuestionnaireOptions"
                    class="form-control form-control-sm bg-light border-0"
                />
            </div>

            <div v-if="selectedProduct.subactivity_question_enabled">
                <h4 class="font-13 text-muted text-uppercase">
                    New Question :
                </h4>
                <p class="mb-1">
                    {{ selectedProduct.subactivity_question.title | removeSpecialChars }}
                </p>
                <div class="form-group d-flex mb-input">
                    <b-form-select
                        v-model="selectedOption"
                        :options="questionOptions"
                        class="form-control form-control-sm bg-light border-0"
                    />
                </div>
            </div>

            <span class="mt-3 text-danger">
                {{ error }}
            </span>
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light mr-2"
                    @click="hideModal"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    class="btn btn-success waves-effect waves-light"
                    @click="saveChanges"
                >
                    Change
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        id: {
            type: String,
            required: true
        },
        orderProductColor: {
            type: Object,
            required: true
        },
        products: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            selectedProduct: {},
            selectedColor: {},
            selectedOldQuestion: null,
            selectedOption: null,
            error: null
        }
    },

    computed: {
        productOptions() {
            return this.products.map(product => {
                return {
                    text: product.name,
                    value: {
                        id: product.id,
                        subactivity_question_enabled:
                            product.subactivity_question_enabled,
                        subactivity_question: product.subactivity_question
                    }
                };
            });
        },

        colorOptions() {
            if (!this.selectedProduct.id) {
                return [];
            }

            const selectedProduct = this.products.find(
                product => product.id === this.selectedProduct.id
            );

            return selectedProduct.colors.map(color => ({
                text: color.name,
                value: {
                    id: color.id,
                    name: color.name,
                    hex: color.hex
                }
            }));
        },

        questionOptions() {
            if (!this.selectedProduct.subactivity_question_enabled) {
                return [];
            }

            return this.selectedProduct.subactivity_question.options.map(
                option => ({
                    text: option.content,
                    value: option
                })
            );
        },

        oldQuestionnaireOptions() {
            return [
                {
                    text: '',
                    value: null
                },
                ...this.orderProductColor.questionnaire.map(option => ({
                    text: option.questionIdentifier,
                    value: option
                }))
            ];
        }
    },

    methods: {
        ...mapActions({
            updateOrderProductColor: 'orderProductColors/update'
        }),

        openModalHandler() {
            const { productColor } = this.orderProductColor;

            this.selectedColor = {
                id: productColor.id,
                name: productColor.name,
                hex: productColor.hex
            };

            this.selectedProduct = {
                id: productColor.product.id,
                subactivity_question_enabled:
                    productColor.product.subactivity_question_enabled,
                subactivity_question: productColor.product.subactivity_question
            };

            this.selectedOption = null;
            this.error = null;
        },

        onProductChange() {
            const newColor = this.colorOptions.find(
                color => color.value.hex === this.selectedColor.hex
            );

            this.selectedColor = newColor ? newColor.value : {};

            this.selectedOption = null;
        },

        async saveChanges() {
            this.error = null;

            if (!this.selectedColor.id) {
                this.error = 'Color is required.';

                return;
            }

            if (
                this.selectedProduct.subactivity_question_enabled &&
                !this.selectedOption
            ) {
                this.error = 'New question is required.';

                return;
            }

            let questionnaire = [...this.orderProductColor.questionnaire];

            if (this.selectedOldQuestion) {
                questionnaire = questionnaire.filter(
                    question =>
                        question.questionIdentifier !==
                        this.selectedOldQuestion.questionIdentifier
                );
            }

            if (this.selectedOption) {
                const subactivityQuestion = {
                    ...this.selectedProduct.subactivity_question,
                    type: 'single',
                    isSubactivityQuestion: true,
                    data: this.selectedOption
                };

                delete subactivityQuestion.options;

                questionnaire.push(subactivityQuestion);
            }

            try {
                await this.updateOrderProductColor({
                    id: this.orderProductColor.id,
                    product_color_id: this.selectedColor.id,
                    questionnaire
                });

                location.reload();
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        hideModal() {
            this.$bvModal.hide(this.id);
        }
    }
}
</script>

<style lang="scss" scoped>
.mb-input {
    margin-bottom: 14px;
}

.colour-block {
    height: 31px;
    width: 31px;
    flex-shrink: 0;
    border-radius: 0.2rem;
    background-color: #F1F5F7;

    &.is-white {
        border: 1px solid #ced4da;
    }
}
</style>
